import React from "react";
import ReactDOM from "react-dom";
import {authManager, ConnectToServices} from "@common/authentication";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";

const params = new URLSearchParams(window.location.search);

let token = params.get("token");
let tokenFromAdmin: string = token ? token : "";
if (_.isEmpty(token)) {
    token = authManager.getTokenFromAdmin();
}

ReactDOM.render(
    <React.StrictMode>
        <ConnectToServices tokenFromAdmin={tokenFromAdmin}>
            <App />
        </ConnectToServices>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
