import React, {useState, useEffect} from "react";
import "../../App.css";
import {UnitsService} from "@common/units-api";
import {authManager} from "@common/authentication";
import toast from "react-hot-toast";
import {Button} from "@vacasa/react-components-lib";

export const Information = () => {
    const token = authManager.getJwt();
    const unitService = new UnitsService(token);
    const [isLoading, setIsLoading] = useState(true);
    const [uuId, setUuId] = useState<string>();
    const [chosenValue, setChosenValue] = useState<string>();
    const [lastModifiedRealState, setLastModifiedRealState] = useState<string>();
    const [lastModifiedRealStateDate, setLastModifiedRealStateDate] = useState<string>();
    const info = authManager.getInfoFromAdmin<any>();
    const canView = info.canView || info.canEdit;
    const canEdit = info.canEdit;
    const options = [
        {value: "Not For Sale", display: "Not For Sale"},
        {value: "Coming Soon", display: "Coming Soon"},
        {value: "For Sale", display: "For Sale"},
    ];
    const getData = async (unitId: number) => {
        try {
            setIsLoading(true);
            const uuid = await unitService.getUuidByLegacyUnitIdV2(unitId);
            setUuId(uuid);
            const unit = await unitService.getUnitByUnitIdV2(uuid);
            await getLastModified(uuid);
            setChosenValue(unit.attributes.real_estate_market_status ? unit.attributes.real_estate_market_status : "Not For Sale");
        } catch (e) {
            toast.error("An error occurred while loading the data.");
        } finally {
            setIsLoading(false);
        }
    };

    const getLastModified = async (uuid: string) => {
        const lastModifiedRealState = await unitService.getHistoryChangesByUnitId(uuid, "units", "real_estate_market_status");
        if (lastModifiedRealState.length > 0) {
            const lastModified = lastModifiedRealState[lastModifiedRealState.length - 1].attributes.done_by;
            if (isNaN(lastModified) && validateEmail(lastModified)) {
                setLastModifiedRealState(lastModified);
            } else {
                setLastModifiedRealState("-");
            }

            const lastModifiedRealStateDate = lastModifiedRealState[lastModifiedRealState.length - 1].attributes.done_at || "-";
            setLastModifiedRealStateDate(lastModifiedRealStateDate.split("T")[0]);
        } else {
            setLastModifiedRealState("-");
            setLastModifiedRealStateDate("-");
        }
    };
    useEffect(() => {
        if (canView) {
            getData(info.unitId);
        }
    }, []);

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const save = async () => {
        const data = {
            data: {
                type: "unit",
                attributes: {
                    real_estate_market_status: chosenValue,
                    done_by: info.user,
                },
            },
        };
        try {
            await unitService.patchUnit(uuId, data);
            await getLastModified(uuId);
            toast.success("saved successfully");
        } catch (e) {
            toast.error("An error occurred while saving the data.");
        }
    };

    return (
        <div className="summaryApp">
            {isLoading ? (
                <div className="row">
                    <div className={`col centerContent}`}>
                        <h6>Loading data...</h6>
                    </div>
                </div>
            ) : canView ? (
                <React.Fragment>
                    <div className="row">
                        <div className={"col-3 col-margin"}>
                            <label>Real Estate Market Status</label>
                            <select
                                className="form-control"
                                placeholder="Select"
                                onChange={(e) => setChosenValue(e.target.value)}
                                value={chosenValue || ""}
                                disabled={!canEdit}
                                id={`type_1`}
                                name="type"
                            >
                                {options.map((type, index) => {
                                    return (
                                        <option key={index} value={type.value}>
                                            {type.display}
                                        </option>
                                    );
                                })}
                            </select>
                            <div style={{fontSize: "13px", marginTop: "5px"}}>
                                <b style={{float: "left"}}>Last modified:&nbsp;</b>
                                <div style={{display: "grid"}}>{lastModifiedRealState}</div>
                                <b style={{float: "left"}}>Last modified date:&nbsp;</b>
                                <div style={{display: "grid"}}>{lastModifiedRealStateDate}</div>
                            </div>
                        </div>
                        {canEdit ? (
                            <div className={"col-3"}>
                                <Button customClass="button-margin" variant="secondary" onClick={save}>
                                    Save
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </React.Fragment>
            ) : null}
        </div>
    );
};
